import { computed, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useSnackbar } from 'vue3-snackbar';
import useCompare from '@/services/useCompare';
import useResultadoFile from '../../Resultados/services/useResultadoFile';

export const useResultadoLegacyForm = (service) => {
    const { shallowEqual } = useCompare();
    const {
        // getFiles,
        orderFiles,
        // removeFile
    } = useResultadoFile();
    const store = useStore();
    const snackbar = useSnackbar();

    const examenTemplate = ref({});
    const documentDataKey = ref(Date.now());

    const state = reactive({
        id: '',
        consecutive: 0,
        paciente: '',
        examen: '',
        template: '',
        doctor: '',
        institucion: '',
        // tratante: '',
        date: '',
        status: 1,
        mode: '2',
        documentData: {},
        documentStatus: '',
        files: [],
        key: '',
        renders: [],
        internalRenders: '',
        observaciones: [],
    });

    const disabled = computed(() => {
        if (state.documentStatus > 1 && state.id) {
            return true;
        }
        return false;
    });

    /** ***** Autocompleters ***** */

    const examenAutocomplete = ref(); // autocomplete input
    const examenSelected = ref();
    const onSelectExamen = (examen) => {
        state.examen = examen.id;
        examenAutocomplete.value.setText(examen.name);
        examenSelected.value = examen;
    };

    /** ***** Validator ***** */
    const rules = computed(() => ({
        paciente: { required },
        examen: { required },
        doctor: { required },
        institucion: { required },
        date: { required },
    }));
    const v = useVuelidate(rules, state);

    const setState = (c) => {
        state.id = c.id;
        state.paciente = c.paciente.id;
        state.examen = c.examen.id;
        state.template = c.template?.id;
        state.doctor = c.doctor.id;
        state.institucion = c.institucion?.id;
        // state.tratante = c.tratante.id;
        state.date = c.date;
        state.status = c.status;
        state.mode = c.mode;
        state.documentStatus = c.documentStatus.toString();
        state.consecutive = c.consecutive;
        state.documentData = { ...c.documentData } || {};
        state.files = c.files ? c.files : [];
        state.renders = c.renders ? c.renders : [];
        state.internalRenders = c.internalRenders ? c.internalRenders : [];
        state.key = c.key;
        state.observaciones = c.observaciones;

        examenSelected.value = c.examen;
        if (examenAutocomplete.value) examenAutocomplete.value.setText(c.examen?.name || '');
        examenTemplate.value = c.template;
        documentDataKey.value = Date.now();
    };

    const resetForm = () => {
        v.value.$reset();
        state.id = '';
        state.consecutive = 0;
        state.paciente = '';
        state.examen = '';
        state.template = '';
        state.doctor = '';
        state.institucion = '';
        // state.tratante = '';
        state.date = '';
        state.status = 1;
        state.mode = 1;
        state.documentData = {};
        state.documentStatus = '';
        state.files = [];
        state.key = '';
        state.renders = [];
        state.internalRenders = '';
        state.observaciones = [];

        examenSelected.value = null;
        examenAutocomplete.value.setText('');
        examenTemplate.value = {};
        // eslint-disable-next-line no-self-assign
        documentDataKey.value = Date.now();
    };

    const handleAction = async (action, data = {}) => {
        await v.value.$validate();
        if (!v.value.$error) {
            // No errors
            let text;
            switch (action) {
                case 'new':
                case 'new_view':
                    await service.createResultado({
                        paciente: state.paciente,
                        doctor: state.doctor,
                        institucion: state.institucion,
                        // tratante: state.tratante,
                        examen: state.examen,
                        date: state.date,
                        status: state.status,
                        documentData: state.documentData,
                        observaciones: state.observaciones,
                    });
                    if (action === 'new_view') {
                        store.dispatch('ContentManager/openWindow', {
                            id: `ResultadoLegacyForm_${service.resultado.value.id}`,
                            component: 'ResultadoLegacyForm',
                            name: 'Resultado',
                            params: {
                                title: 'Resultado',
                                headerTitle: 'Editar Resultado',
                                item: { ...service.resultado.value },
                            },
                        });
                    }
                    resetForm();
                    text = 'Resultado creado';
                    break;
                case 'save_sh':
                    await service.updateResultado(state.id, {
                        paciente: state.paciente,
                        doctor: state.doctor,
                        institucion: state.institucion,
                        // tratante: state.tratante,
                        examen: state.examen,
                        template: state.template,
                        date: state.date,
                        status: state.status,
                        documentData: state.documentData,
                        observaciones: state.observaciones,
                    });
                    break;
                case 'save':
                    await service.updateResultado(state.id, {
                        paciente: state.paciente,
                        doctor: state.doctor,
                        institucion: state.institucion,
                        // tratante: state.tratante,
                        examen: state.examen,
                        template: state.template,
                        date: state.date,
                        status: state.status,
                        documentData: state.documentData,
                        observaciones: state.observaciones,
                    });
                    text = 'Resultado actualizado';
                    break;
                case 'generate':
                    await service.updateResultado(state.id, {
                        paciente: state.paciente,
                        doctor: state.doctor,
                        institucion: state.institucion,
                        // tratante: state.tratante,
                        examen: state.examen,
                        template: state.template,
                        date: state.date,
                        status: state.status,
                        documentData: state.documentData,
                        observaciones: state.observaciones,
                    });
                    await service.generateDocument(state.id, data.templateId, data.files);
                    text = 'Documento generado';
                    break;
                case 'merge_files':
                    await service.updateResultado(state.id, {
                        paciente: state.paciente,
                        doctor: state.doctor,
                        institucion: state.institucion,
                        // tratante: state.tratante,
                        examen: state.examen,
                        template: state.template,
                        date: state.date,
                        status: state.status,
                        documentData: state.documentData,
                        observaciones: state.observaciones,
                    });
                    await service.mergeFiles(state.id, data.files);
                    text = 'Documento generado';
                    break;
                case 'close':
                    await service.closeResultado(state.id);
                    text = 'Documento finalizado';
                    break;
                case 'goback':
                    await service.gobackDocument(state.id);
                    text = 'Documento generado';
                    break;
                case 'publish':
                    await service.publishDocument(state.id);
                    text = 'Documento publicado';
                    break;
                case 'resend':
                    await service.resendDocument(state.id);
                    text = 'Documento enviado';
                    break;
                case 'merge_close':
                    await service.updateResultado(state.id, {
                        paciente: state.paciente,
                        doctor: state.doctor,
                        institucion: state.institucion,
                        // tratante: state.tratante,
                        examen: state.examen,
                        template: state.template,
                        date: state.date,
                        status: state.status,
                        documentData: state.documentData,
                        observaciones: state.observaciones,
                    });
                    await service.mergeFiles(
                        state.id,
                        state.files.map((f) => f.file.id)
                    );
                    setState(service.resultado.value);
                    state.files[state.files.length - 1].selected = true;
                    await orderFiles(
                        state.id,
                        // eslint-disable-next-line no-underscore-dangle
                        state.files.map((f) => ({ id: f.file._id, selected: f.selected }))
                    );
                    await service.closeResultado(state.id);
                    text = 'Documento finalizado';
                    break;
                case 'reset-renders':
                    await service.resetRenders(state.id);
                    text = 'Ahora puede Editar el Documento';
                    break;
                default:
                    break;
            }
            if (text) {
                snackbar.add({
                    type: 'success',
                    text,
                });
                setState(service.resultado.value);
            }
            return true;
        }
        console.log(v.value.$errors);
        snackbar.add({
            type: 'error',
            text: 'Ups no podemos enviar la informacion sin algunos datos',
        });
        return false;
    };

    const setInstitucion = (value) => {
        if (!state.documentStatus || state.documentStatus === '1') {
            if (state.institucion !== value.id) state.institucion = value.id;
        }
    };

    const handleAddNewObservacion = (data) => {
        state.observaciones.push(data.observacion);
        if (state.id) handleAction('save_sh');
    };

    const handledeleteNewObservacion = (data) => {
        // console.log('data.index', data.index);
        state.observaciones = state.observaciones.filter((o, i) => {
            console.log('i', i);
            return i !== data.index;
        });
        if (state.id) handleAction('save_sh');
    };

    /** ***** Files Callbacks ***** */
    const handleOrderFiles = async () => {
        // eslint-disable-next-line no-underscore-dangle
        const files = state.files.map((f) => ({ id: f.file._id, selected: f.selected }));
        await service.orderFiles(state.id, files);
        state.files = await service.getFiles(state.id);
    };

    const handleRemoveFile = async (fileId) => {
        await service.removeFile(state.id, fileId);
        state.files = state.files.filter((i) => i.file.id !== fileId);
    };

    const handleRemoveRender = async (renderId) => {
        await service.removeRender(state.id, renderId);
        setState();
    };

    const handleResend = (email) => {
        service.resendDocument(state.id, email);
    };

    // eslint-disable-next-line arrow-body-style
    const isUpdate = computed(() => {
        if (service.resultado.value.documentData) {
            return (
                shallowEqual(
                    {
                        paciente: service.resultado.value.paciente.id,
                        doctor: service.resultado.value.doctor.id,
                        examen: service.resultado.value.examen.id,
                        date: service.resultado.value.date,
                        status: service.resultado.value.status,
                    },
                    {
                        paciente: state.paciente,
                        doctor: state.doctor,
                        examen: state.examen,
                        date: state.date,
                        status: Number(state.status),
                        // eslint-disable-next-line comma-dangle
                    }
                ) && shallowEqual(service.resultado.value.documentData, state.documentData)
            );
        }
        return (
            shallowEqual(
                {
                    paciente: service.resultado.value.paciente.id,
                    doctor: service.resultado.value.doctor.id,
                    examen: service.resultado.value.examen.id,
                    date: service.resultado.value.date,
                    status: service.resultado.value.status,
                },
                {
                    paciente: state.paciente,
                    doctor: state.doctor,
                    examen: state.examen,
                    date: state.date,
                    status: Number(state.status),
                    // eslint-disable-next-line comma-dangle
                }
            ) && shallowEqual({}, state.documentData)
        );
    });

    /** Helpers */
    const downloadUrl = computed(
        // eslint-disable-next-line comma-dangle
        () => `${process.env.VUE_APP_DARMIN_RESULTADOS_URL}/documento/examen/${state.key}`
    );

    return {
        disabled,
        v,
        state,
        setState,
        resetForm,
        handleAction,
        onSelectExamen,
        setInstitucion,
        handleAddNewObservacion,
        handledeleteNewObservacion,
        handleOrderFiles,
        handleRemoveFile,
        handleRemoveRender,
        handleResend,
        examenAutocomplete,
        examenSelected,
        isUpdate,
        downloadUrl,
    };
};

export default {};
